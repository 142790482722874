import {
  Box,
  Container,
  Grid,
  lighten,
  Typography,
  useTheme,
} from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import Listing from "./Listing"

const ListingSection = ({ inputListings = [], heading, bgcolor }) => {
  const theme = useTheme()
  let {
    allContentfulListing: { nodes: listings },
  } = useStaticQuery(graphql`
    query {
      allContentfulListing(
        limit: 3
        sort: { order: DESC, fields: createdAt }
        filter: { active: { eq: true } }
      ) {
        nodes {
          ...Listing
        }
      }
    }
  `)
  if (inputListings.length) {
    listings = inputListings
  } else if (listings.length < 3) {
    listings = [...listings, ...new Array(3 - listings.length).fill({})]
  }
  return (
    <Box bgcolor={bgcolor || lighten(theme.palette.primary.light, 0.5)}>
      <Container>
        <Box py={2}>
          {heading && (
            <Box pb={2} textAlign="center">
              <Typography variant="h2" style={{ marginBottom: 0 }}>
                {heading}
              </Typography>
            </Box>
          )}
          <Grid container justify="center">
            {listings.map((listing, i) => (
              <Grid
                item
                md={4}
                xs={12}
                key={`listing-${i}`}
                flex={1}
                mb={{ xs: 1 }}
                mr={{ md: i < listings.length - 1 && 2 }}
              >
                <Listing {...listing} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default ListingSection
