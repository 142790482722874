import { Box, Button, Container } from "@material-ui/core"
import { graphql } from "gatsby"
import HeroSection from "../components/HeroSection"
import Layout from "../components/Layout"
import ListingSection from "../components/ListingSection"
import ContactForm from "../components/ContactForm"
import TownDetails from "../components/TownDetails"
import listingsPath from "../util/listingsPath"

const Town = ({
  pageContext: town,
  data: {
    image,
    listings: { nodes: listings },
  },
  ...other
}) => {
  const { name } = town
  return (
    <Layout title={`Community Guide: ${name}`}>
      <HeroSection
        title={`Neighborhood Guide`}
        backgroundImage={image.fixed.src}
      />
      <Container>
        <Box py={2}>
          <TownDetails {...town} />
        </Box>
      </Container>
      {!!listings.length && (
        <>
          <ListingSection
            heading={`Active Listings in ${town.name}`}
            inputListings={listings}
          />
          {listings.length > 3 && (
            <Button
              component="a"
              href={listingsPath({ townName: town.name, type: `For Sale` })}
            >
              More Listings
            </Button>
          )}
        </>
      )}
      <ContactForm />
    </Layout>
  )
}

export const PageQuery = graphql`
  query Town($listingIds: String!) {
    image: contentfulAsset(contentful_id: { eq: "3r4I47DpyZmsk3QT6k7AZG" }) {
      fixed(width: 1400, quality: 95) {
        src
      }
    }
    listings: allContentfulListing(
      limit: 3
      filter: { id: { regex: $listingIds }, active: { eq: true } }
    ) {
      nodes {
        ...Listing
      }
    }
  }
`
export default Town
